import { css, variant } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'

import { WriskTheme } from '../../themes'

export type LogoProperty = 'default'

export interface LogoProps {
  variant?: LogoProperty
  className?: string
  id?: string
}

export const LogoImage: FunctionComponent<LogoProps> = ({ className, id }: LogoProps) => {
  const theme = useTheme() as WriskTheme
  return (
    <img
      id={id}
      className={className}
      src={theme.logo.regular as string}
      alt='home'
      data-test='logo'
    />
  )
}

export const Logo = styled(LogoImage)(
  css({
    fontSize: 0,
  }),
  variant({
    scale: 'Logo',
    prop: 'variant',
    variants: {
      default: {
        display: 'block',
        height: '1.5rem',
      },
    },
  }),
)

Logo.defaultProps = {
  variant: 'default',
}
