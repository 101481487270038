import {
  border,
  color,
  ColorProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { transition, TransitionProps } from '../../transition'
import { mdBumps } from '../layout'
import { InputProps } from './Input'

export type InputWrapperProperty = 'default' | 'ghost'

export type InputWrapperProps = InputProps &
  TransitionProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  FlexboxProps &
  ColorProps & {
    variant?: ResponsiveValue<InputWrapperProperty>
  }

export const InputWrapper = styled.div<InputWrapperProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
  }),
  variant({
    scale: 'InputWrapper',
    variants: {
      default: {
        borderRadius: 2,
        px: mdBumps,
        bg: 'chalk',
        boxShadow: 'default',
        '&:focus-within': {
          boxShadow: 'input',
        },
      },
      ghost: {
        px: 0,
        boxShadow: 'none',
      },
    },
  }),
  compose(flexbox, transition, border, space, layout, typography, color),
)

InputWrapper.defaultProps = {
  variant: 'default',
}
