import React, { FormEvent, FunctionComponent, useCallback } from 'react'

export type FormProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> & { 'data-testid'?: string }

const style = {
  width: '100%',
}

export const Form: FunctionComponent<FormProps> = ({ onSubmit, children, ...props }) => {
  const onFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSubmit?.(event)
    },
    [onSubmit],
  )

  return (
    <form style={style} autoComplete='off' {...props} onSubmit={onSubmit && onFormSubmit}>
      {children}
    </form>
  )
}
