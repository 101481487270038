import {
  compose,
  css,
  position,
  PositionProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { animated } from 'react-spring'
import styled from 'styled-components'

export interface MenuProps extends PropsWithChildren, SpaceProps, PositionProps {
  className?: string
  onDismiss: () => void
  visible: boolean
}

export const Menu: FunctionComponent<MenuProps> = ({
  onDismiss,
  children,
  visible,
  ...props
}) => {
  return visible ? (
    <>
      <MenuFog onClick={onDismiss} />
      <AnimatedMenu {...props}>{children}</AnimatedMenu>
    </>
  ) : null
}

const MenuFog = styled.div(
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 999,
  }),
)

const AnimatedMenu = styled(animated.div)<SpaceProps>(
  css({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    zIndex: 999,
    top: 0,
  }),
  compose(space, position),
)
