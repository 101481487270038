import React, { ReactElement, ReactNode, useCallback, useReducer } from 'react'

import { OverridableConfirmationView } from './OverridableConfirmationView'

export interface SearchViewWithOverridableConfirmationProps<T> {
  value?: T
  renderConfirmation: (value: T) => ReactNode | null
  renderSearch: (updateValue: (value: T) => void) => ReactElement
  onChange: (value: T | undefined) => void
  changeAction: string
  overrideAction?: string
  isOverridable?: boolean
  renderOverrideForm?: (
    updateValue: (value: T) => void,
    onCancel: () => void,
  ) => ReactElement
}

type Mode = 'view' | 'change' | 'override'

type Action<T> =
  | { type: 'SET_VALUE'; payload: T }
  | { type: 'CHANGE' }
  | { type: 'OVERRIDE' }
  | { type: 'RESET' }

interface State<T> {
  mode: Mode
  value?: T
}

const reducer = <T,>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'SET_VALUE':
      return { mode: 'view', value: action.payload }
    case 'CHANGE':
      return { mode: 'change', value: undefined }
    case 'OVERRIDE':
      return { ...state, mode: 'override' }
    case 'RESET':
      return { mode: 'view', value: state.value }
    default:
      return state
  }
}

export const SearchViewWithOverridableConfirmation = <T,>({
  value,
  renderConfirmation,
  renderSearch,
  onChange,
  renderOverrideForm,
  ...props
}: SearchViewWithOverridableConfirmationProps<T>): ReactElement => {
  const initialState: State<T> = {
    mode: value ? 'view' : 'change',
    value: value,
  }

  const [state, dispatch] = useReducer(reducer<T>, initialState)

  const updateValue = useCallback(
    (value: T) => {
      dispatch({ type: 'SET_VALUE', payload: value })
      onChange(value)
    },
    [onChange],
  )

  if (state.mode === 'view' && state.value) {
    return (
      <OverridableConfirmationView
        label={renderConfirmation(state.value)}
        onChange={() => dispatch({ type: 'CHANGE' })}
        onOverride={() => dispatch({ type: 'OVERRIDE' })}
        {...props}
      />
    )
  }
  if (state.mode === 'override' && renderOverrideForm) {
    return renderOverrideForm(updateValue, () => dispatch({ type: 'RESET' }))
  }
  return renderSearch(updateValue)
}
