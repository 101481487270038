import {
  Box,
  BoxProps,
  Flex,
  Heading,
  mdBumps,
  PrimaryButton,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { PaymentMethod } from '../../../../domain'
import { useSetupMandate } from '../../../../hooks/directDebit'
import {
  TKey,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.direct-debit-setup')

export interface DirectDebitSetupProps extends BoxProps {
  mandate: PaymentMethod | undefined
}

export const DirectDebitSetup: FunctionComponent<DirectDebitSetupProps> = ({
  mandate,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const onSetupMandate = useSetupMandate()

  return (
    <Box width={1} variant='raised' px={mdBumps} py={5} {...props}>
      {mandate ? (
        <Heading>
          {t(tPaymentMethods('bacs', 'redacted'), {
            bankName: mandate.bankName,
            accountNumberEnding: mandate.accountNumberEnding,
          })}
        </Heading>
      ) : (
        <Flex>
          <Heading>{t(tKey('header'))}</Heading>
          <PrimaryButton
            data-testid='setup-direct-debit'
            layout='small'
            onClick={onSetupMandate.execute}
            disabled={onSetupMandate.loading}
          >
            {t(tKey('actions.add'))}
          </PrimaryButton>
        </Flex>
      )}
    </Box>
  )
}
