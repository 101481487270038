import { compose, css, layout, WidthProps } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Box } from '../atoms'

export interface ProgressProps extends WidthProps {
  percentage?: number
  className?: string
}

const Component: FunctionComponent<ProgressProps> = ({ className, percentage = 0 }) => {
  const normalisedPercentage = Math.min(percentage, 1)

  return (
    <div className={className}>
      <Box className='bar' width={normalisedPercentage}></Box>
    </div>
  )
}

export const Progress = styled(Component)(
  css({
    display: 'flex',
    justifyContent: 'flex-start',
    height: '4px',
    borderRadius: '2px',
    backgroundColor: 'progressBackgroundColor',
    [Box]: {
      height: '100%',
      transition: 'width 250ms ease',
      borderRadius: '2px',
      backgroundColor: 'progressBarColor',
    },
  }),
  compose(layout),
)
