import { system } from '@wrisk/styled-system'

export interface HoverColorProps {
  hoverColor?: string
}

export const hoverColor = system({
  hoverColor: {
    scale: 'colors',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: [':hover'] as any,
    transform: (value: string | number, scale) => ({
      color: scale?.[value] ?? value,
      borderColor: scale?.[value] ?? value,
    }),
  },
})
