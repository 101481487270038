import { SearchViewWithOverridableConfirmation } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { Address } from '../../../domain'
import { useWriskTranslation } from '../../../infrastructure/internationalisation'
import { AddressManualEntry, AddressManualEntryProps } from './AddressManualEntry'
import { AddressSearch, AddressSearchProps } from './AddressSearch'

export interface AddressOverridableLookupProps
  extends AddressSearchProps,
    AddressManualEntryProps {
  isOverridable: boolean
}

export const AddressOverridableLookup: FunctionComponent<
  AddressOverridableLookupProps
> = ({
  name,
  value,
  delay,
  countries,
  onChange,
  isOverridable,
  errorMessageClass,
  tKey,
  tName,
}) => {
  const { t } = useWriskTranslation()

  const renderConfirmationLabel = useCallback(
    ({ label }: Address) => <pre>{label}</pre>,
    [],
  )

  const renderSearch = useCallback(
    (onUpdate: (value: Address) => void) => (
      <AddressSearch
        name={name}
        onChange={onUpdate}
        delay={delay}
        countries={countries}
        placeholder={t<string>(tKey(tName, 'placeholder'))}
        notFound={t<string>(tKey(tName, 'validation', 'notFound'))}
      />
    ),
    [countries, delay, name, t, tKey, tName],
  )

  const renderOverrideForm = useCallback(
    (onUpdate: (value: Address) => void, onCancel: () => void) => (
      <AddressManualEntry
        name={name}
        value={value}
        onChange={onUpdate}
        onCancel={onCancel}
        errorMessageClass={errorMessageClass}
        tKey={tKey}
        tName={tName}
      />
    ),
    [errorMessageClass, name, tKey, tName, value],
  )

  return (
    <SearchViewWithOverridableConfirmation
      value={value}
      onChange={onChange}
      renderConfirmation={renderConfirmationLabel}
      renderSearch={renderSearch}
      isOverridable={isOverridable}
      renderOverrideForm={renderOverrideForm}
      changeAction={t<string>(tKey('actions.change'))}
      overrideAction={t<string>(tKey('actions.override'))}
    />
  )
}
