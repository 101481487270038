import { ObjectOrArray, system } from '@wrisk/styled-system'
// eslint-disable-next-line import/no-unresolved
import { Properties } from 'csstype'

export interface CollapseProps {
  collapseWhenEmpty?: boolean
}

export const collapse = system({
  collapseWhenEmpty: {
    property: '&:empty' as keyof Properties,
    transform: (value: boolean): ObjectOrArray<number> =>
      value
        ? {
            marginTop: 0,
            marginBottom: 0,
          }
        : {},
  },
})
