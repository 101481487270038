import {
  compose,
  css,
  DisplayProps,
  flexbox,
  FlexboxProps,
  layout,
  variant,
} from '@wrisk/styled-system'
import { default as React, FunctionComponent } from 'react'
import styled from 'styled-components'

import { Box, BoxProps, mdBumps, xsBumps } from '../layout'
import { Heading, Typo } from '../typography'

export type SectionProperty = 'default'

export interface SectionProps extends FlexboxProps, BoxProps, DisplayProps {
  variant?: SectionProperty
}

export const Section = styled(Box)<SectionProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  variant({
    scale: 'Section',
    variants: {
      default: {},
    },
  }),
  compose(flexbox, layout),
)

Section.defaultProps = {
  variant: 'default',
}

export const SectionTitle: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box {...props}>
    <Heading variant='h3' as='h3' typoSize='lg'>
      {children}
    </Heading>
  </Box>
)

export const SectionDescription: FunctionComponent<BoxProps> = ({
  children,
  ...props
}) => (
  <Box width={1} pt={xsBumps} {...props}>
    <Typo color='bodySecondary'>{children}</Typo>
  </Box>
)

export const SectionContent: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} pt={mdBumps} {...props}>
    <Typo>{children}</Typo>
  </Box>
)
