import { ResponsiveValue, system } from '@wrisk/styled-system'
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'

export interface WordBreakProps {
  wordBreak?: ResponsiveValue<Property.WordBreak>
}

export const wordBreak = system({
  wordBreak: true,
})
