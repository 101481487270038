import {
  border,
  BorderProps,
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { xsBumps } from '../layout'
import { mdBumps } from '../layout/bumps'

export interface EndorsementNotificationProps
  extends BorderProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PropsWithChildren {}

export const EndorsementNotification = styled.div<EndorsementNotificationProps>(
  ({ theme }) =>
    css({
      width: '100%',
      bg: 'surfaceWarningSubdued',
      boxShadow: `inset 4px 0px ${theme.colors.surfaceWarning}`,
      py: xsBumps,
      px: mdBumps,
    }),
  compose(space, layout, border, flexbox),
)
