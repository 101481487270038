import { FlexProps } from '@wrisk/styled-system'
import {
  Box,
  Flex,
  Icon,
  InputMessage,
  InputWrapper,
  SelectResult,
  SelectResults,
  smBumps,
  TextInput,
  Typo,
} from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Address, FindAddressItem, isAddress } from '../../../domain'
import {
  useApiErrorHandlingAsync,
  useApiErrorHandlingAsyncCallback,
} from '../../../hooks/auth'
import { usePrincipal } from '../../authentication'

export interface AddressSearchProps extends FlexProps {
  name: string
  placeholder?: string
  notFound?: string
  onChange: (address: Address) => void
  delay?: number
  countries?: string[]
}

export const AddressSearch: FunctionComponent<AddressSearchProps> = ({
  name,
  delay = 250,
  placeholder,
  notFound,
  countries,
  onChange,
  ...props
}) => {
  const { apiClient } = usePrincipal()

  const [text, setText] = useState('')
  const [searchTerm] = useDebounce(text, delay)

  const [results, setResults] = useState<FindAddressItem[] | undefined>(undefined)

  const { loading: termLoading } = useApiErrorHandlingAsync(
    async (text: string) =>
      setResults(
        text.trim()
          ? await apiClient.findAddresses(text.trim(), { countries })
          : undefined,
      ),
    [searchTerm],
  )

  const { loading: itemLoading, execute: onItemClick } = useApiErrorHandlingAsyncCallback(
    async (item: FindAddressItem) => {
      isAddress(item)
        ? onChange(await apiClient.getAddress(item.id))
        : setResults(
            await apiClient.findAddresses(item.text, { countries, container: item.id }),
          )
    },
  )

  const loading = termLoading || itemLoading

  const onTextChange = useCallback((e) => setText(e.target.value), [])

  const resultsComponent = results?.length ? (
    <SelectResults>
      {results.map((item) => (
        <SelectResult key={item.id} onClickParams={item} onClick={onItemClick}>
          <Box width={1}>
            <Typo as='span' display='inline'>
              {item.text}
            </Typo>
            &nbsp;
            <Typo as='span' display='inline' color='bodySecondary'>
              {item.description}
            </Typo>
          </Box>
          {!isAddress(item) && <Icon icon='chevronRight' size='tiny' ml={2} />}
        </SelectResult>
      ))}
    </SelectResults>
  ) : Boolean(text) && Boolean(notFound) && !isNil(results) ? (
    <InputMessage>{notFound}</InputMessage>
  ) : null

  return (
    <Flex width={1} gap={2} flexDirection='column' alignItems='flex-start' {...props}>
      <InputWrapper width={1}>
        <Flex flexGrow={1} mr={2} justifyContent='flex-start'>
          <Icon icon='search' size='small' mr={smBumps} />
          <TextInput
            role='listbox'
            disabled={itemLoading}
            variant='ghost'
            id={name}
            name={name}
            value={text}
            placeholder={placeholder}
            onChange={onTextChange}
            data-1p-ignore={true}
            flexGrow={1}
          />
        </Flex>
        {loading && <Icon icon='loadingDark' size='tiny' />}
      </InputWrapper>
      {resultsComponent}
    </Flex>
  )
}
