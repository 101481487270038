import { Box, Flex, InputWrapper, smBumps, TextInput, Typo } from '@wrisk/ui-components'
import { isEmpty } from 'lodash'
import React, { FunctionComponent, useMemo } from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'

import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AddressFieldsConfig } from './AddressManualEntry'

interface AddressManualEntryFieldsProps {
  config: AddressFieldsConfig
  name: string
  tKey: TKeyBuilder
  tName: string
  errorMessageClass?: string
}

export const AddressManualEntryFields: FunctionComponent<
  AddressManualEntryFieldsProps
> = ({ config, name, tKey, tName, errorMessageClass }) => {
  const { t } = useWriskTranslation()
  const {
    control,
    getValues,
    formState: { errors: formErrors, defaultValues },
  } = useFormContext()

  const fields = useMemo(() => {
    const inputValues = getValues()[name] ?? {}
    const defaultFieldValues = defaultValues?.[name] ?? {}

    return Object.entries(config)
      .filter(([, fieldConfig]) => !fieldConfig.hidden)
      .filter(([field, fieldConfig]) =>
        [
          fieldConfig.required,
          !isEmpty(defaultFieldValues[field]), // existing (saved) address fields
          !isEmpty(inputValues[field]), // address fields after search
        ].some(Boolean),
      )
  }, [config, defaultValues, getValues, name])

  const addressErrors = get(formErrors, name)

  return (
    <Flex width={1} gap={4} flexDirection='column' alignItems='flex-start'>
      {fields.map(([field, fieldConfig]) => {
        const error = addressErrors?.[field]
        return (
          <Controller
            key={field}
            name={`${name}.${field}`}
            control={control}
            rules={{ required: fieldConfig.required }}
            render={({ field: { name: controllerName, value, onChange, onBlur } }) => (
              <Flex
                width={1}
                gap={smBumps}
                flexDirection='column'
                alignItems='flex-start'
              >
                <InputWrapper
                  width={1}
                  backgroundColor={fieldConfig.readonly ? 'surfaceDisabled' : undefined}
                >
                  <TextInput
                    width={1}
                    variant='ghost'
                    id={controllerName}
                    name={controllerName}
                    value={value}
                    disabled={fieldConfig.readonly}
                    backgroundColor='inherit'
                    color={fieldConfig.readonly ? 'textDisabled' : undefined}
                    placeholder={t<string>(tKey(tName, 'fields', field))}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </InputWrapper>
                {Boolean(errorMessageClass) && error?.type ? (
                  <Box className={errorMessageClass}>
                    <Typo color='textCritical'>
                      {t([
                        tKey(tName, 'validation', error.type),
                        `defaults.validation.${error.type}`,
                      ])}
                    </Typo>
                  </Box>
                ) : null}
              </Flex>
            )}
          />
        )
      })}
    </Flex>
  )
}
