import {
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { smBumps, xxsBumps } from '../layout'

export type ListProps = LayoutProps & SpaceProps

export const List = styled.ul<ListProps>(
  css({
    padding: xxsBumps,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: smBumps,
  }),
  compose(layout, space),
)
