import { SpaceProps } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote, isCoverActive, PriceOption, Quote } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { TKey } from '../../../infrastructure/internationalisation'
import { useProposal } from '../../product/proposal/proposalContext'
import { PromotionBanner } from './PromotionBanner'
import { PromotionNotification } from './PromotionNotification'

interface ContentProps extends SpaceProps {
  quote: Quote
  discount: PriceOption
}

const tKey = TKey('components.promotion-banner')

const Content: FunctionComponent<ContentProps> = ({ discount, quote, ...props }) =>
  isCoverActive(discount) ? (
    <PromotionBanner tKey={tKey} quote={quote} {...props} />
  ) : (
    <PromotionNotification discount={discount} {...props} />
  )

export const ProposalPromotionBanner: FunctionComponent<SpaceProps> = (props) => {
  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)
  const [discount] = useDiscountCovers(quote)

  return discount ? <Content quote={quote} discount={discount} {...props} /> : null
}
