import {
  Box,
  DateInput,
  DateInputs,
  Form,
  fromInputsToIsoDate,
  fromIsoDateToInputs,
  Heading,
  mdBumps,
  ModalHeader,
  PrimaryButton,
  TextInput,
  Typo,
  useModal,
  xxlBumps,
} from '@wrisk/ui-components'
import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { Trans } from 'react-i18next'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { RetrieveRequest } from '../../clients/anonymous'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../infrastructure/internationalisation'
import { getRetrieve, useConfig } from '../../state/configuration'
import { isAxiosError } from '../../util/axios'
import { AnonymousPrincipal } from '../authentication'
import { Chat } from '../organisms/Chat'
import { ExternalLink } from '../organisms/links'
import { SignIn } from '../organisms/SignIn'
import { FullPage } from '../templates'
import { RetrievePath } from './RetrievePath'

const tKey = TKey('pages.retrieve')

const isValidPostalCode = (input: string, regex?: string): boolean =>
  regex ? new RegExp(regex).test(input) : true

const RetrieveError: FunctionComponent<{ title: ReactNode; message: ReactNode }> = ({
  title,
  message,
}) => (
  <Box>
    <ModalHeader header={title} />
    <Typo>{message}</Typo>
  </Box>
)

export interface RetrievePageProps {
  tName: string
  principal: AnonymousPrincipal
}

export const RetrievePage: FunctionComponent<RetrievePageProps> = ({
  tName,
  principal,
}) => {
  const { t } = useWriskTranslation()
  const [params] = useSearchParams()

  const navigate = useNavigate()

  const modal = useModal()

  const retrieve = useConfig(getRetrieve)

  const ref = params.get('ref') ?? undefined

  const datePlaceholders = useMemo(
    () => ({
      day: t(tDefaults('inputs.date.placeholders.day')),
      month: t(tDefaults('inputs.date.placeholders.month')),
      year: t(tDefaults('inputs.date.placeholders.year')),
    }),
    [t],
  )

  const mapExceptionToMessage = (error: unknown): string =>
    isAxiosError(error) && error.response?.status === 401
      ? error.response.status.toString()
      : 'unknown'

  const onSubmit = useAsyncCallback(async () => {
    if (!isTokenRequest(tokenRequest)) {
      return
    }

    try {
      await principal.retrieveAnonymous(tokenRequest)
      navigate(generatePath(RetrievePath.RETRIEVE_PROPOSAL, tokenRequest))
    } catch (e) {
      const messageKey = mapExceptionToMessage(e)

      const components = {
        Chat: <Chat />,
        SignIn: <SignIn />,
        ExternalLink: <ExternalLink />,
      }

      const title = (
        <Trans
          t={t}
          i18nKey={tKey(tName, `errors.${messageKey}.header`)}
          components={components}
        />
      )
      const message = (
        <Trans
          t={t}
          i18nKey={tKey(tName, `errors.${messageKey}.message`)}
          components={components}
        />
      )

      modal.show({
        content: <RetrieveError title={title} message={message} />,
      })
    }
  })

  const [tokenRequest, setTokenRequest] = useState<Partial<RetrieveRequest>>({
    externalReference: ref,
  })

  const onExternalReferenceChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setTokenRequest((old) => ({ ...old, externalReference: target.value }))
    },
    [setTokenRequest],
  )

  const onDateOfBirthChange = useCallback(
    (inputs: DateInputs) => {
      setTokenRequest((old) => ({ ...old, dateOfBirth: fromInputsToIsoDate(inputs) }))
    },
    [setTokenRequest],
  )

  const onPostalCodeChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setTokenRequest((old) => ({
        ...old,
        postalCode: target.value.toUpperCase(),
      }))
    },
    [setTokenRequest],
  )

  const isTokenRequest = useCallback(
    (request: Partial<RetrieveRequest>): request is RetrieveRequest => {
      return Boolean(
        request.externalReference &&
          request.postalCode &&
          isValidPostalCode(request.postalCode, retrieve?.formats?.postalCode) &&
          request.dateOfBirth,
      )
    },
    [retrieve],
  )

  return (
    <FullPage
      pageId='retrieve-page'
      header={t(tKey(tName, 'header'))}
      subheader={t(tKey(tName, 'subheader'))}
    >
      <Form onSubmit={onSubmit.execute} data-testid='retrieve-quote'>
        {!ref && (
          <Box width={1} mb={xxlBumps}>
            <Heading variant='h3' typoSize='md' pb={mdBumps}>
              {t(tKey(tName, 'form.external-reference.header'))}
            </Heading>
            <TextInput
              width={[1, 1, 4 / 5]}
              value={tokenRequest.externalReference ?? ''}
              onChange={onExternalReferenceChange}
              placeholder={t<string>(tKey(tName, 'form.external-reference.placeholder'))}
            />
          </Box>
        )}

        <Box width={1} mb={xxlBumps}>
          <Heading variant='h3' typoSize='md' pb={mdBumps}>
            {t(tKey(tName, 'form.date-of-birth.header'))}
          </Heading>
          <DateInput
            dateInputs={fromIsoDateToInputs(tokenRequest.dateOfBirth)}
            onChange={onDateOfBirthChange}
            placeholders={datePlaceholders}
          />
        </Box>

        <Box width={1} mb={xxlBumps}>
          <Heading variant='h3' typoSize='md' pb={mdBumps}>
            {t(tKey(tName, 'form.postal-code.header'))}
          </Heading>
          <TextInput
            value={tokenRequest.postalCode ?? ''}
            onChange={onPostalCodeChange}
            placeholder={t<string>(tKey(tName, 'form.postal-code.placeholder'))}
          />
        </Box>

        <PrimaryButton
          layout='fixed'
          type='submit'
          loading={onSubmit.loading}
          disabled={!isTokenRequest(tokenRequest)}
        >
          {t(tKey(tName, 'form.submit'))}
        </PrimaryButton>
      </Form>
    </FullPage>
  )
}
