import {
  Box,
  Checkbox,
  Flex,
  FlexAnchor,
  HelpLink,
  List,
  ListItem,
  mdBumps,
  Notification,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const CheckboxEligibilityFormInput: FunctionComponent<FormInputProps<boolean>> = ({
  value,
  onChange,
  name,
  t,
  tKey,
  tName,
}) => {
  const id = `${name}-check`

  const consentItems = t(tKey(tName, 'items'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const link = t(tKey(tName, 'link'), { defaultValue: null })

  return (
    <Flex flexDirection='column' alignItems='flex-start' gap={mdBumps}>
      {consentItems.length ? (
        <Notification
          type='standalone'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={smBumps}
        >
          <List>
            {consentItems.map((item, i) => (
              <ListItem key={i} variant='bullet'>
                {item}
              </ListItem>
            ))}
          </List>
          {link && (
            <HelpLink
              linkText={t(tKey(tName, 'link-label'))}
              link={link}
              isOpenModal={true}
            />
          )}
        </Notification>
      ) : null}
      <Box variant='raised' p={2} width={1}>
        <FlexAnchor
          as='label'
          variant='highlight'
          justifyContent='flex-start'
          alignItems='flex-start'
          htmlFor={id}
          width={1}
          p={4}
        >
          <Checkbox name={id} id={id} value={value} onChange={onChange} mr={4} />
          <Typo as='pre'>{t(tKey(tName, 'input-label'))}</Typo>
        </FlexAnchor>
      </Box>
    </Flex>
  )
}
