import {
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { TypoSizeProps, typoSizes } from '../typography'
import { Anchor, AnchorProps } from './Anchor'

export type NavAnchorProperty = 'default' | 'inverted'

export interface NavAnchorProps
  extends AnchorProps,
    TypoSizeProps,
    LayoutProps,
    SpaceProps,
    ColorProps {
  variant?: ResponsiveStyleValue<NavAnchorProperty>
}

// TODO (MK): Remove typoSizes from this and have it controlled by css and variants instead.
// Rather than having a snowflake of the variant overriding the prop as is
export const NavAnchor = styled(Anchor)<NavAnchorProps>(
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'navbar',
    color: 'textOnNavigation',
    whiteSpace: 'nowrap',
    px: [2, 2, 4],
    py: 2,
    borderRadius: 1,
    '&:hover': {
      backgroundColor: 'hover',
    },
  }),
  typoSizes,
  variant({
    scale: 'NavAnchor',
    variants: {
      default: {},
      inverted: {},
    },
  }),
  compose(layout, space, color),
)

NavAnchor.defaultProps = {
  variant: 'default',
  typoSize: 'sm',
}
