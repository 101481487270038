import { isEmpty, isNil } from 'lodash'

import { FindAddressItem } from './types'

export const isAddress = (addressItem: FindAddressItem) => addressItem.type === 'Address'

export const generateLabel = (fields: string[], inputValues: Record<string, string>) =>
  fields
    .filter((it) => !isNil(inputValues[it]) && !isEmpty(inputValues[it]))
    .map((it) => inputValues[it])
    .join('\n')
