import React, { FunctionComponent } from 'react'

import { Address } from '../../../domain'
import { usePrincipal } from '../../authentication'
import { AddressOverridableLookup } from '../../organisms/address/AddressOverridableLookup'
import { useMaybePolicy } from '../../product/policy/policyContext'
import { FormInputProps } from '../types'

export interface AddressFormMeta {
  countries?: string[]
  overridable?: boolean
}

export const AddressFormInput: FunctionComponent<
  FormInputProps<Address, AddressFormMeta | undefined>
> = ({ name, value, onChange, meta, tKey, tName, errorMessageClass }) => {
  const { isAdmin } = usePrincipal()
  const policy = useMaybePolicy()
  const isOverridable = Boolean(meta?.overridable) && Boolean(policy) && isAdmin

  return (
    <AddressOverridableLookup
      name={name}
      value={value}
      countries={meta?.countries}
      isOverridable={isOverridable}
      onChange={onChange}
      tKey={tKey}
      tName={tName}
      errorMessageClass={errorMessageClass}
    />
  )
}
