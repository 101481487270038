import { SystemStyleObject } from '@wrisk/styled-system'

import { ButtonProps } from '../Button'

export type ButtonDisabledProperty = 'default'

export interface ButtonDisabledProps {
  $disabledState?: ButtonDisabledProperty
}

export const buttonDisabledMapping = ({
  disabled,
}: ButtonProps): ButtonDisabledProps => ({
  $disabledState: disabled ? 'default' : undefined,
})

export const buttonDisabledVariants: Record<ButtonDisabledProperty, SystemStyleObject> = {
  default: {
    opacity: 0.35,
  },
}
