import { ResponsiveValue, system } from '@wrisk/styled-system'
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'

export interface CursorProps {
  cursor?: ResponsiveValue<Property.Cursor>
}

export const cursor = system({
  cursor: true,
})
