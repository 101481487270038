import { compose, css, layout, LayoutProps, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { mdBumps, smBumps } from '../atoms'

export type CollapsibleSectionContentProperty = 'default'

export interface CollapsibleSectionContentProps extends LayoutProps {
  variant?: CollapsibleSectionContentProperty
}

export const CollapsibleSectionContent = styled.div<CollapsibleSectionContentProps>(
  css({
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    px: smBumps,
    py: mdBumps,
  }),
  variant({
    scale: 'CollapsibleSectionContent',
    variants: {
      default: {},
    },
  }),
  compose(layout),
)

CollapsibleSectionContent.defaultProps = {
  variant: 'default',
}
