import { SpaceProps } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'

import { inForce } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { TKey } from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'
import { PromotionBanner } from './PromotionBanner'

const tKey = TKey('components.promotion-banner')

export const PolicyPromotionBanner: FunctionComponent<SpaceProps> = (props) => {
  const {
    policy: {
      currentPolicyVersion: { quote },
    },
  } = usePolicy()

  const [discount] = useDiscountCovers(quote)

  return inForce(discount) ? (
    <PromotionBanner tKey={tKey} quote={quote} {...props} />
  ) : null
}
