import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  css,
  position,
  PositionProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { textDecoration, TextDecorationProps } from '../../textDecoration'
import {
  textTransform,
  TextTransformProps,
  TypoSizeProps,
  typoSizes,
} from '../typography'
import { Anchor, AnchorProps } from './Anchor'
import { hoverColor, HoverColorProps } from './hoverColor'

export type LinkAnchorProperty = 'standalone' | 'inline'

export type LinkAnchorProps = Omit<
  AnchorProps &
    TypoSizeProps &
    TextDecorationProps &
    BorderProps &
    TypographyProps &
    ColorProps &
    TextTransformProps &
    PositionProps &
    HoverColorProps,
  'ref'
> & {
  variant?: LinkAnchorProperty
}

export const LinkAnchor = styled(Anchor)<LinkAnchorProps>(
  css({
    display: 'inline-block',
    fontWeight: 'bold',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    fontFamily: 'buttons',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  }),
  (props) =>
    props.disabled
      ? {
          opacity: 0.35,
          cursor: 'not-allowed',
        }
      : {},
  variant({
    scale: 'LinkAnchor',
    variants: {
      standalone: {
        color: 'hyperlink',
        borderColor: 'hyperlink',
        '&:hover': {
          color: 'primaryAccent',
          borderColor: 'primaryAccent',
        },
      },
      inline: {
        '&:hover': {
          color: 'primaryAccent',
          borderColor: 'primaryAccent',
        },
      },
    },
  }),
  compose(
    typoSizes,
    textDecoration,
    border,
    typography,
    color,
    textTransform,
    position,
    hoverColor,
  ),
)

LinkAnchor.defaultProps = {
  variant: 'inline',
}
