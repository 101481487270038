import { usePrincipal } from '../application/authentication'
import { tDefaults, useWriskTranslation } from '../infrastructure/internationalisation'
import { useApiErrorHandlingAsyncCallback } from './auth'

export const useSetupMandate = () => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  return useApiErrorHandlingAsyncCallback(async () => {
    const redirectFlow = await apiClient.createRedirectFlow({
      mandateDescription: t(tDefaults('payment-methods.description')),
      successUrl: window.location.href,
    })

    window.location.href = redirectFlow.redirectUrl
  })
}
