import {
  Box,
  Form,
  mdBumps,
  ModalHeader,
  PrimaryButton,
  RadioButton,
  RadioButtonGrid,
} from '@wrisk/ui-components'
import React, { FormEvent, useCallback } from 'react'

import { getCoreAsset, Quote } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'

interface VoluntaryExcessModalProps {
  onChange: (excess: number) => void
  loading: boolean
  quote: Quote
}

const tKey = TKey('components.voluntary-excess-modal')

export const VoluntaryExcessModal: React.FC<VoluntaryExcessModalProps> = ({
  onChange,
  loading,
  quote,
}) => {
  const { t } = useWriskTranslation()

  const {
    product: { excess },
  } = useProduct()

  const asset = getCoreAsset(quote)

  const onEdit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      onChange(Number(new FormData(e.currentTarget).get('excess')))
    },
    [onChange],
  )

  const radioInput = (
    <RadioButtonGrid width={1} columns={4}>
      {excess?.voluntaryExcess?.map((it) => (
        <RadioButton
          key={it}
          name='excess'
          value={it}
          id={it.toString()}
          defaultChecked={it === asset.voluntaryExcess}
          data-testid={`excess${it}`}
        >
          {t(tFormats('currency.short'), { amount: it })}
        </RadioButton>
      ))}
    </RadioButtonGrid>
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Form data-testid='excess-form' onSubmit={onEdit}>
        {radioInput}
        <PrimaryButton
          loading={loading}
          type='submit'
          data-testid='modal-button-save'
          mt={mdBumps}
          width={1}
          layout='fixed'
        >
          {t(tKey('actions.confirm'))}
        </PrimaryButton>
      </Form>
    </Box>
  )
}
