import { SpaceProps } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote, isCoverActive } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { TKey } from '../../../infrastructure/internationalisation'
import { useProposal } from '../../product/proposal/proposalContext'
import { PromotionBanner } from './PromotionBanner'

const tKey = TKey('components.promotion-banner')

export const RenewalPromotionBanner: FunctionComponent<SpaceProps> = (props) => {
  const { proposal } = useProposal()
  const quote = getCurrentQuote(proposal)
  const [discount] = useDiscountCovers(quote)

  return isCoverActive(discount) ? (
    <PromotionBanner tKey={tKey} quote={quote} variant='narrow' {...props} />
  ) : null
}
