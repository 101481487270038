import {
  BackgroundColorProps,
  border,
  BorderProps,
  color,
  compose,
  css,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from '@wrisk/styled-system'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { transition, TransitionProps } from '../../transition'
import { containerStyle, ContainerStyleProps } from './containerStyle'

export type BoxProps = LayoutProps &
  SpaceProps &
  BorderProps &
  BackgroundColorProps &
  ContainerStyleProps &
  TransitionProps &
  PositionProps &
  PropsWithChildren

export const Box = styled.div<BoxProps>(
  css({
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  containerStyle,
  compose(layout, position, space, border, color, transition),
)

Box.defaultProps = {}
