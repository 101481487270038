import { FlexProps } from '@wrisk/styled-system'
import {
  Anchor,
  Box,
  Flex,
  Heading,
  HelpLink,
  Icon,
  IconButton,
  IconsTheme,
  lgBumps,
  mdBumps,
  PrimaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { hasPolicyStarted } from '../../../../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../../../../hooks/errors'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { useCurrentPath } from '../../../../../../infrastructure/routing'
import { getFeatureToggles, useConfig } from '../../../../../../state/configuration'
import { pathWithOrigin } from '../../../../../../util/dom'
import { stripSpaces } from '../../../../../../util/string'
import { usePrincipal } from '../../../../../authentication'
import { usePolicy } from '../../../policyContext'

const tKey = TKey('components.claims-sidebar')

export const ClaimsSidebar: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()
  const { axitechFnol } = useConfig(getFeatureToggles)

  const telephone = t(tDefaults('claim.telephone'))
  const email = t(tDefaults('claim.email'))
  const faqLink = t('links.claimsFaq', { defaultValue: null })

  const path = useCurrentPath()

  const makeAClaimOnline = useErrorHandlingAsyncCallback(async () => {
    window.location.href = await apiClient.createFnolLink(
      policy.policyId,
      t('links.privacy-policy'),
      pathWithOrigin(path),
      telephone,
      email,
    )
  })

  const contactSections = [
    {
      headingKey: tKey('telephone'),
      text: telephone,
      href: `tel:${stripSpaces(telephone)}`,
      icon: 'call' as keyof IconsTheme,
    },
    {
      headingKey: tKey('email'),
      text: email,
      href: `mailto:${email}`,
      icon: 'email' as keyof IconsTheme,
    },
  ].map(({ headingKey, text, href, icon }) => (
    <Flex key={headingKey} width={1} px={mdBumps} my={mdBumps}>
      <Box mr={2}>
        <Typo fontWeight='bold' pb={1}>
          {t(headingKey)}
        </Typo>
        <Typo typoSize='sm'>{text}</Typo>
      </Box>
      <IconButton variant='primary' as={Anchor} href={href}>
        <Icon icon={icon} size='small' />
      </IconButton>
    </Flex>
  ))

  return (
    <Flex
      width={1}
      flexDirection='column'
      alignItems='flex-start'
      variant='raised'
      mb={lgBumps}
      {...props}
    >
      <Box width={1} borderBottomWidth={1} p={mdBumps}>
        <Heading variant='h3' typoSize='md'>
          {t(tKey('header'))}
        </Heading>
      </Box>
      {axitechFnol && (
        <Flex justifyContent='center' width={1} px={mdBumps} mt={mdBumps}>
          <PrimaryButton
            width={1}
            onClick={makeAClaimOnline.execute}
            disabled={!hasPolicyStarted(policy)}
          >
            {t(tKey('makeAClaimOnline'))}
          </PrimaryButton>
        </Flex>
      )}
      <Box width={1}>{contactSections}</Box>
      {faqLink && (
        <Box width={1} p={mdBumps} borderTopWidth={1}>
          <HelpLink linkText={t(tKey('faq'))} link={faqLink} isOpenModal={true} />
        </Box>
      )}
    </Flex>
  )
}
