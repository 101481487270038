import { css, variant } from '@wrisk/styled-system'
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from 'react'
import styled from 'styled-components'

import { Anchor, Icon } from '../../atoms'
import { Modal } from './Modal'

export interface ModalContextProps {
  content: ReactElement
  onClose?: () => void
  variant?: ModalProperty
}

export interface ModalContextObj {
  show: (props: ModalContextProps) => void
  hide: () => void
}

export type ModalProperty = 'default' | 'iframe' | 'feedback'

interface ModalProps {
  variant?: ModalProperty
}

const ModalContext = createContext<ModalContextObj>({
  show: () => undefined,
  hide: () => undefined,
})

export const ModalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [props, setProps] = useState<ModalContextProps>()
  const onClose = props?.onClose

  const show = useCallback((newProps: ModalContextProps) => {
    setProps(newProps)
    setOpen(true)
  }, [])

  const hide = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  return (
    <ModalContext.Provider value={{ show, hide }}>
      {children}
      <Modal open={open} onFogClick={hide}>
        <ModalContainer variant={props?.variant}>
          <ModalClose onClick={hide}>
            <Icon icon='cross' size='medium' />
          </ModalClose>
          <ModalContents variant={props?.variant}>{props?.content}</ModalContents>
        </ModalContainer>
      </Modal>
    </ModalContext.Provider>
  )
}

const ModalContents = styled.div<ModalProps>(
  {
    width: '100%',
  },
  variant({
    scale: 'ModalContents',
    variants: {
      default: {
        px: [9, 10, 12],
        py: [9, 10, 12],
      },
      iframe: {
        p: 0,
      },
      feedback: {
        px: [9, 10, 12],
        py: [9, 10, 12],
      },
    },
  }),
)

ModalContents.defaultProps = {
  variant: 'default',
}

const ModalClose = styled(Anchor)(
  css({
    zIndex: '1005',
    position: 'sticky',
    float: 'right',
    top: [2, 2, 4],
    right: [2, 2, 4],
    padding: [1, 1, 2],
    cursor: 'pointer',
    borderRadius: '100%',

    '&:hover': {
      backgroundColor: 'surfaceHighlight',
    },
  }),
)

const ModalContainer = styled.div<ModalProps>(
  {
    '-webkit-overflow-scrolling': 'touch',
    ['&:focus']: {
      outline: 'none',
    },
  },
  css({
    zIndex: 1001,
    backgroundColor: 'background',
    boxShadow: 'default',
    borderTopLeftRadius: 1,
    borderTopRightRadius: 1,
    borderBottomLeftRadius: [0, 1],
    borderBottomRightRadius: [0, 1],

    width: ['100%', '90%', '100%'],
    maxWidth: '584px',

    position: 'absolute',

    top: ['auto', '50%'],
    left: [0, '50%'],
    bottom: [0, 'auto'],
    right: [0, 'auto'],

    transform: ['none', 'translate(-50%, -50%)'],

    overflowY: 'auto',

    maxHeight: '66%',
  }),
  variant({
    scale: 'ModalContainer',
    variants: {
      default: {
        minHeight: ['33%', 'auto'],
      },
      iframe: {
        minHeight: ['66%', '66%'],
      },
      feedback: {
        minHeight: ['50%', '50%'],
        maxHeight: '85%',
        top: ['auto', 'auto'],
        left: [0, 'auto'],
        bottom: [0, 0],
        right: [0, 0],
        marginRight: [0, '12px', '80px'],
        borderBottomLeftRadius: [0, 0],
        borderBottomRightRadius: [0, 0],
        transform: ['none', 'none'],
        maxWidth: ['480px'],
      },
    },
  }),
)

ModalContainer.defaultProps = {
  variant: 'default',
}

export const useModal = (): ModalContextObj => useContext(ModalContext)
