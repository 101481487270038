import { ButtonProps } from '../Button'
import { buttonDisabledMapping, ButtonDisabledProps } from './disabled'
import { buttonLoadingMapping, ButtonLoadingProps } from './loading'

export type ButtonStateProps = ButtonLoadingProps & ButtonDisabledProps

export const buttonStateMapping = (props: ButtonProps): ButtonStateProps => ({
  ...buttonLoadingMapping(props),
  ...buttonDisabledMapping(props),
})
