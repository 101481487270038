import {
  color,
  ColorProps,
  compose,
  css,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { TypoSizeProps, typoSizes } from './typoSizes'
import { whiteSpace, WhiteSpaceProps } from './whiteSpace'

export type HeadingProperty = 'default' | 'h1' | 'h2' | 'h3' | 'h4'

export interface HeadingProps
  extends TypoSizeProps,
    TypographyProps,
    ColorProps,
    SpaceProps,
    WhiteSpaceProps {
  variant?: ResponsiveStyleValue<HeadingProperty>
}

export const Heading = styled.div<HeadingProps>(
  css({
    fontWeight: 'bold',
    fontFamily: 'header',
    margin: 0,
  }),
  typoSizes,
  variant({
    scale: 'Heading',
    variants: {
      default: {},
      h1: {},
      h2: {},
      h3: {},
      h4: {},
    },
  }),
  compose(whiteSpace, typography, color, space),
)

Heading.defaultProps = {
  variant: 'default',
  typoSize: 'base',
}
