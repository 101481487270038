import { compose, css, space, SpaceProps } from '@wrisk/styled-system'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { ReactComponent as TrustPilotLogo } from '../../assets/trustPilot.svg'
import { Box, Flex } from '../layout'
import { TypoSizeProps, typoSizes } from '../typography'
import { Anchor } from './Anchor'

export interface TrustPilotAnchorProps extends SpaceProps, TypoSizeProps {
  className?: string
}

const Component: FunctionComponent<PropsWithChildren<TrustPilotAnchorProps>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Anchor
      href='https://www.trustpilot.com/evaluate/www.wrisk.co'
      target='_blank'
      rel='noopener noreferrer'
      className={className}
      {...props}
    >
      <Flex>
        <Box>{children}</Box>
        <Box ml={1}>
          <TrustPilotLogo />
        </Box>
      </Flex>
    </Anchor>
  )
}

export const TrustPilotAnchor = styled(Component)(
  css({
    display: 'inline-block',
    fontFamily: 'buttons',
    fontWeight: 'bold',
    borderRadius: 1,
    bg: 'transparent',
    color: 'body',
    border: 0,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    px: 5,
    py: 3,
    backgroundColor: 'transparent',
    borderColor: '#00B67A',
    borderWidth: '1px',
    borderStyle: 'solid',
  }),
  compose(space, typoSizes),
)

TrustPilotAnchor.defaultProps = {
  typoSize: 'base',
}
