import {
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

export type NavBarProperty = 'default'

export interface NavBarProps extends LayoutProps, SpaceProps, ColorProps {
  variant?: NavBarProperty
}

export const NavBar = styled.div<NavBarProps>(
  ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      zIndex: 999,
      height: theme.navigation.height,
      px: [4, 4, 6, 8],
    }),
  variant({
    scale: 'NavBar',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, color),
)

NavBar.defaultProps = {
  variant: 'default',
}
