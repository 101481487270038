import { ResponsiveValue, system } from '@wrisk/styled-system'
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'

export interface WordWrapProps {
  wordWrap?: ResponsiveValue<Property.WordWrap>
}

export const wordWrap = system({
  wordWrap: true,
})
