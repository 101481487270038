import {
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  system,
  variant,
} from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'

import { IconsTheme } from '../../icons'
import { WriskTheme } from '../../themes'
import { transition, TransitionProps } from '../../transition'

export type IconProperty = 'logo' | 'large' | 'medium' | 'small' | 'tiny' | 'trustPilot'

export interface IconProps extends LayoutProps, TransitionProps, SpaceProps {
  className?: string
  size?: IconProperty
  rotate?: number
  icon: keyof IconsTheme | string
  color?: string | undefined
}

const Component: FunctionComponent<IconProps> = ({ className, icon }) => {
  const theme = useTheme() as WriskTheme
  const MaybeIcon = theme.icons[icon]?.component
  return MaybeIcon ? <MaybeIcon className={className} /> : null
}

const iconColor = system({
  color: {
    scale: 'colors',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties: ['path', 'ellipse'] as any,
    transform: (value: string | number, scale) => ({
      fill: scale?.[value] ?? value,
    }),
  },
})

export const Icon = styled(Component)<IconProps>(
  {
    display: 'block',
  },
  (props) =>
    props.rotate && {
      transform: `rotate(${props.rotate}deg)`,
    },
  variant({
    prop: 'size',
    scale: 'Icon',
    variants: {
      logo: {
        height: '1rem',
      },
      large: {
        size: '2.0rem',
        minWidth: '2.0rem',
      },
      medium: {
        size: '1.5rem',
        minWidth: '1.5rem',
      },
      small: {
        size: '1.25rem',
        minWidth: '1.25rem',
      },
      tiny: {
        size: '1rem',
        minWidth: '1rem',
      },
      trustPilot: {
        width: '98px',
        height: '24px',
      },
    },
  }),
  compose(layout, transition, space, iconColor),
)

Icon.defaultProps = {
  size: 'medium',
}
