import {
  FontSizeProps,
  LineHeightProps,
  ObjectOrArray,
  SystemStyleObject,
  TLengthStyledSystem,
} from '@wrisk/styled-system'
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'
import styled, { css } from 'styled-components'

import {
  AccountIconProperty,
  AppNameProperty,
  BannerProperty,
  ButtonDisabledProperty,
  ButtonLayoutProperty,
  ButtonLoadingProperty,
  ContainerStyleProperty,
  FlexAnchorProperty,
  FooterAnchorProperty,
  HeadingProperty,
  IconButtonProperty,
  IconProperty,
  IllusType,
  InputWrapperProperty,
  LinkAnchorProperty,
  ListItemProperty,
  LogoNavProperty,
  LogoProperty,
  MainSectionProperty,
  MenuBoxProperty,
  NavAnchorProperty,
  NavBarProperty,
  NavigationProperty,
  NotificationLayouts,
  NotificationTypes,
  NotificationVariants,
  PageContainerProperty,
  PlateProperty,
  PrimaryButtonProperty,
  PromotionBannerContainerProperty,
  RadioButtonLayouts,
  RadioButtonTypes,
  SecondaryButtonProperty,
  StatusProperty,
  StyledPremiumProperty,
  TertiaryButtonProperty,
  TextAreaProperty,
  TextInputProperty,
  TypoProperty,
  TypoSizeProperty,
  WideContainerProperty,
} from './atoms'
import { ColorProperty } from './colorProperty'
import { FontFamilyProperty } from './fontFamilyProperty'
import { IconsTheme } from './icons'
import { SelectResultsProperty } from './inputs'
import {
  CollapsibleSectionContentProperty,
  CollapsibleSectionHeaderProperty,
  HelpTextProperty,
  LicencePlateProperty,
  ModalProperty,
  TabAnchorProperty,
  TabBarProperty,
} from './molecules'
import { ShadowProperty } from './shadowProperty'
import { TransitionProperty } from './transition'

styled.div``

export interface WriskTheme {
  // Common
  name: string
  fontFaces: ReturnType<typeof css<object>>
  breakpoints: ObjectOrArray<number | string | symbol>
  space: ObjectOrArray<Property.Margin<number | string>>
  fonts: Record<FontFamilyProperty, Property.FontFamily>
  fontSizes?: ObjectOrArray<Property.FontSize<number>>
  lineHeights?: ObjectOrArray<Property.LineHeight<TLengthStyledSystem>>
  typoSizes: Record<TypoSizeProperty, FontSizeProps & LineHeightProps>
  radii: ObjectOrArray<Property.BorderRadius<TLengthStyledSystem>>
  colors: Record<ColorProperty, Property.Color>
  shadows?: Record<ShadowProperty, string>
  transitions?: Record<TransitionProperty, Property.Transition>
  proposalImages: Record<string, string>
  promoImages?: Record<string, { background?: string; foreground?: string }>
  icons: IconsTheme
  illus: IllusType
  navigation: {
    height: Property.Height<TLengthStyledSystem>[]
  }
  logo: {
    regular: unknown
  }
  containerStyle?: Record<ContainerStyleProperty, SystemStyleObject>
  // Components
  Typo?: Record<TypoProperty, SystemStyleObject>
  Heading?: Record<HeadingProperty, SystemStyleObject>
  WideContainer?: Record<WideContainerProperty, SystemStyleObject>
  Banner?: Record<BannerProperty, SystemStyleObject>
  MainSection?: Record<MainSectionProperty, SystemStyleObject>
  FlexAnchor?: Record<FlexAnchorProperty, SystemStyleObject>
  LinkAnchor?: Record<LinkAnchorProperty, SystemStyleObject>
  NavAnchor?: Record<NavAnchorProperty, SystemStyleObject>
  FooterAnchor?: Record<FooterAnchorProperty, SystemStyleObject>
  AccountIcon?: Record<AccountIconProperty, SystemStyleObject>
  HelpText?: Record<HelpTextProperty, SystemStyleObject>
  Logo?: Record<LogoProperty, SystemStyleObject>
  Icon?: Record<IconProperty, SystemStyleObject>
  Plate?: Record<PlateProperty, SystemStyleObject>
  Status?: Record<StatusProperty, SystemStyleObject>
  Navigation?: Record<NavigationProperty, SystemStyleObject>
  NavBar?: Record<NavBarProperty, SystemStyleObject>
  AppName?: Record<AppNameProperty, SystemStyleObject>
  LogoNav?: Record<LogoNavProperty, SystemStyleObject>
  MenuBox?: Record<MenuBoxProperty, SystemStyleObject>
  TextArea?: Record<TextAreaProperty, SystemStyleObject>
  TextInput?: Record<TextInputProperty, SystemStyleObject>
  InputWrapper?: Record<InputWrapperProperty, SystemStyleObject>
  SelectResults?: Record<SelectResultsProperty, SystemStyleObject>
  StyledPremium?: Record<StyledPremiumProperty, SystemStyleObject>
  TabBar?: Record<TabBarProperty, SystemStyleObject>
  TabAnchor?: Record<TabAnchorProperty, SystemStyleObject>
  PromotionBannerContainer?: Record<PromotionBannerContainerProperty, SystemStyleObject>
  Notification?: {
    variants?: Record<NotificationVariants, SystemStyleObject>
    layouts?: Record<NotificationLayouts, SystemStyleObject>
    type?: Record<NotificationTypes, SystemStyleObject>
  }
  RadioButton?: {
    layouts?: Record<RadioButtonLayouts, SystemStyleObject>
    type?: Record<RadioButtonTypes, SystemStyleObject>
  }
  Button?: {
    base?: SystemStyleObject
    layout?: Record<ButtonLayoutProperty, SystemStyleObject>
  }
  GhostButton?: {
    loading?: Record<ButtonLoadingProperty, SystemStyleObject>
    disabled?: Record<ButtonDisabledProperty, SystemStyleObject>
  }
  PrimaryButton?: {
    variants?: Record<PrimaryButtonProperty, SystemStyleObject>
    loading?: Record<ButtonLoadingProperty, SystemStyleObject>
    disabled?: Record<ButtonDisabledProperty, SystemStyleObject>
  }
  SecondaryButton?: {
    variants?: Record<SecondaryButtonProperty, SystemStyleObject>
    loading?: Record<ButtonLoadingProperty, SystemStyleObject>
    disabled?: Record<ButtonDisabledProperty, SystemStyleObject>
  }
  TertiaryButton?: {
    variants?: Record<TertiaryButtonProperty, SystemStyleObject>
    loading?: Record<ButtonLoadingProperty, SystemStyleObject>
    disabled?: Record<ButtonDisabledProperty, SystemStyleObject>
  }
  IconButton?: {
    variants?: Record<IconButtonProperty, SystemStyleObject>
    disabled?: Record<ButtonDisabledProperty, SystemStyleObject>
  }
  CollapsibleSectionContent?: Record<CollapsibleSectionContentProperty, SystemStyleObject>
  CollapsibleSectionHeader?: Record<CollapsibleSectionHeaderProperty, SystemStyleObject>
  LicencePlate?: Record<LicencePlateProperty, SystemStyleObject>
  ModalContainer?: Record<ModalProperty, SystemStyleObject>
  ModalContents?: Record<ModalProperty, SystemStyleObject>
  PageContainer?: Record<PageContainerProperty, SystemStyleObject>
  ListItem?: Record<ListItemProperty, SystemStyleObject>
}
