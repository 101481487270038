import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

export type NavigationProperty = 'default'

export interface NavigationProps
  extends LayoutProps,
    SpaceProps,
    ColorProps,
    BorderProps {
  variant?: ResponsiveValue<NavigationProperty>
}

export const Navigation = styled.div<NavigationProps>(
  css({
    width: '100%',
    position: 'relative',
    bg: 'surfaceNavigation',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'border',
  }),
  variant({
    scale: 'Navigation',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, color, border),
)

Navigation.defaultProps = {
  variant: 'default',
}
