import {
  PrimaryButton,
  Section,
  SectionContent,
  SectionDescription,
  SectionTitle,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getUsablePaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../../hooks/auth'
import { useQuotePremiums } from '../../../../../hooks/premiums'
import {
  TKey,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../../authentication'
import { FullPage } from '../../../../templates'
import { useProduct } from '../../../productContext'
import { useProposal } from '../../proposalContext'
import { DirectDebitSetup } from '../DirectDebitSetup'

interface CheckoutBACSNoDepositProps {
  paymentMethods: PaymentMethod[]
}

const tKey = TKey('pages.checkout-bacs')

export const CheckoutBACS: FunctionComponent<CheckoutBACSNoDepositProps> = ({
  paymentMethods,
}) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  const { product } = useProduct()
  const { proposal, setProposal } = useProposal()
  const { totals } = useQuotePremiums(proposal.quote, product)

  const paymentMethod = getUsablePaymentMethod(
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )

  const onCheckout = useApiErrorHandlingAsyncCallback(async () =>
    setProposal(await apiClient.bindProposal(proposal.proposalCode)),
  )

  const mandateDescription = totals.discounted
    ? t(tKey('mandate', 'subheader', 'pay'), {
        statement: t(tPaymentMethods('statement')),
        defaultValue: null,
      })
    : t(tKey('mandate', 'subheader', 'bind'), { defaultValue: null })

  const buttonDescription = t(tKey('actions', 'bind'))

  return (
    <FullPage pageId='checkout-page' header={t(tKey('header'))}>
      <Section width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('mandate', 'header'))}</SectionTitle>
        {mandateDescription && (
          <SectionDescription>{mandateDescription}</SectionDescription>
        )}
        <SectionContent>
          <DirectDebitSetup mandate={paymentMethod} />
        </SectionContent>
      </Section>

      <PrimaryButton
        data-testid='bind-proposal'
        layout='fixed'
        onClick={onCheckout.execute}
        loading={onCheckout.loading}
        disabled={!paymentMethod}
      >
        {buttonDescription}
      </PrimaryButton>
    </FullPage>
  )
}
