import { compose, css, space, SpaceProps, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { Anchor, TypoSizeProps, typoSizes } from '../atoms'

export type TabAnchorProperty = 'active' | 'inactive'

export interface TabAnchorProps extends SpaceProps, TypoSizeProps {
  selected?: boolean
  variant?: TabAnchorProperty
}

export const TabAnchor = styled(Anchor).attrs<TabAnchorProps>((props) => ({
  variant: props.selected ? 'active' : 'inactive',
}))<TabAnchorProps>(
  css({
    py: 3,
    borderWidth: 0,
    borderStyle: 'solid',
    borderBottomWidth: 3,
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'buttons',
    fontWeight: 'bold',
  }),
  variant({
    scale: 'TabAnchor',
    variants: {
      active: {
        color: 'body',
        borderColor: 'primaryAccent',
      },
      inactive: {
        color: 'bodySecondary',
        borderColor: 'transparent',
        '&:hover': {
          color: 'body',
        },
      },
    },
  }),
  compose(typoSizes, space),
)

TabAnchor.defaultProps = {
  variant: 'inactive',
}
