import { Box, BoxProps, InvoiceItemCell, InvoiceTotalCell } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerPayment,
  getCurrentQuote,
  getPaymentPremium,
  Proposal,
} from '../../../../domain'
import { hasDiscount, useQuotePremiums } from '../../../../hooks/premiums'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { IPT } from '../../../organisms/product'
import { useProduct } from '../../productContext'

export interface PaymentDueProps extends BoxProps {
  payment: CustomerPayment | undefined
  proposal: Proposal
}

const tKey = TKey('components.payment-due')

export const PaymentDue: FunctionComponent<PaymentDueProps> = ({
  payment,
  proposal,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)
  const paymentPremium = getPaymentPremium(payment)

  const { breakdown, totals } = useQuotePremiums(quote, product)

  const creditOrDebitAmount = paymentPremium - totals.discounted

  return (
    <Box p={2} variant='raised' {...props}>
      <InvoiceItemCell
        header={t<string>(tKey('headers', 'premium'), {
          period: t(tFormats('duration'), { value: proposal.policyTerm }),
        })}
        premium={t<string>(tFormats('currency.long'), {
          amount: totals.gross,
        })}
      />
      {hasDiscount(breakdown) && (
        <InvoiceItemCell
          header={t<string>(tKey('headers', 'promotion'))}
          premium={t<string>(tFormats('currency.long'), { amount: -breakdown.discount })}
        />
      )}
      {creditOrDebitAmount > 0.001 && (
        <InvoiceItemCell
          header={t<string>(tKey('headers', 'debit'))}
          premium={t<string>(tFormats('currency.long'), { amount: creditOrDebitAmount })}
        />
      )}
      {creditOrDebitAmount < -0.001 && (
        <InvoiceItemCell
          header={t<string>(tKey('headers', 'credit'))}
          premium={t<string>(tFormats('currency.long'), { amount: creditOrDebitAmount })}
        />
      )}
      <InvoiceTotalCell
        header={t<string>(tKey('headers', 'total'))}
        subheader={<IPT />}
        premium={t<string>(tFormats('currency.long'), { amount: paymentPremium })}
      />
    </Box>
  )
}
