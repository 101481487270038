import { SearchViewWithOverridableConfirmation } from '@wrisk/ui-components'
import React, { FunctionComponent, HTMLInputTypeAttribute, useCallback } from 'react'

import { GetDataEntry } from '../../../../domain'
import { tDefaults } from '../../../../infrastructure/internationalisation'
import { FormInputProps } from '../../types'
import { DataEntrySearchView } from './DataEntrySearchView'

interface DataEntryMeta {
  tableCode: string
  placeholder: string
  characterLimit: number
  inputType: HTMLInputTypeAttribute
  regExp: string
}

export const DataEntryInput: FunctionComponent<
  FormInputProps<GetDataEntry, DataEntryMeta>
> = ({
  name,
  value,
  onChange,
  tKey,
  t,
  meta: { tableCode, placeholder, characterLimit, inputType, regExp },
}) => {
  const renderSearch = useCallback(
    (f: (r: GetDataEntry) => void) => (
      <DataEntrySearchView
        name={name}
        value={value}
        tableCode={tableCode}
        tKey={tKey}
        t={t}
        onChange={f}
        placeholder={placeholder}
        characterLimit={characterLimit}
        regExp={regExp}
        type={inputType}
      />
    ),
    [characterLimit, tableCode, inputType, name, placeholder, regExp, t, tKey, value],
  )

  const renderConfirmation = useCallback((t: GetDataEntry) => t.name, [])

  return (
    <SearchViewWithOverridableConfirmation
      value={value}
      onChange={onChange}
      renderConfirmation={renderConfirmation}
      renderSearch={renderSearch}
      changeAction={t(tDefaults('actions.change'))}
    />
  )
}
