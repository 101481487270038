import {
  LinkAnchor,
  NotificationContainer,
  Section,
  SectionContent,
  SectionTitle,
  smBumps,
  useModal,
  xlBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  getCurrentQuote,
  getPolicyholder,
  isRenewalProposal,
  RenewalProposal,
} from '../../../domain'
import { useNavigateCallback } from '../../../hooks/navigation'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../infrastructure/routing'
import { getProduct, ManagementType, useConfig } from '../../../state/configuration'
import { toDateTime } from '../../../util/date'
import { AppPath } from '../../AppPath'
import { ExcessSection } from '../../organisms/excess'
import { ProductDocumentsSection } from '../../organisms/product'
import { RenewalPromotionBanner } from '../../organisms/promotion'
import {
  ProposalCoverSection,
  ProposalInformationSection,
  ProposalVoluntaryExcessModal,
} from '../../organisms/proposal'
import { FullPage } from '../../templates'
import { useProduct } from '../productContext'
import { ProposalAdjustmentPricing } from '../proposal/pricing'
import { useProposal } from '../proposal/proposalContext'
import { ProposalPath } from '../proposal/ProposalPath'
import { AdditionalProfileManagement } from './components/AdditionalProfileManagement'
import { QuoteManagement } from './components/QuoteManagement'
import { RenewalAddOnSection } from './components/RenewalAddOnSection'

const tKey = TKey('pages.renewal-quote')

interface ContentProps {
  home: Page
  proposal: RenewalProposal
}

const RenewalQuotePageContent: FunctionComponent<ContentProps> = ({ home, proposal }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const modal = useModal()
  const upButton = useUpButton(home)
  const { product, scheme } = useProduct()
  const {
    excess,
    renewal: { sections },
  } = useConfig(getProduct(scheme.schemeCode))

  const quote = getCurrentQuote(proposal)
  const profile = getPolicyholder(quote)

  const onOptOut = useNavigateCallback(ProposalPath.OPT_OUT)

  const onAddOnChange = useCallback(() => navigate(ProposalPath.ADD_ONS), [navigate])

  const onExcessChange = useCallback(
    () =>
      modal.show({
        content: <ProposalVoluntaryExcessModal onComplete={modal.hide} />,
      }),
    [modal],
  )

  const header = t(tKey('header'), { profile })
  const subheader = (
    <Trans
      t={t}
      i18nKey={tKey('subheader')}
      values={{
        startAt: t(tFormats('datetime.medium'), {
          value: toDateTime(proposal.sourcePolicy.detail.expiredAt),
        }),
      }}
      components={{
        OptOutLink: <LinkAnchor onClick={onOptOut} />,
      }}
    />
  )

  const configuredSections = sections.map((it) => {
    const content = (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return <AdditionalProfileManagement config={it} quote={quote} />
        default:
          return <QuoteManagement config={it} quote={quote} />
      }
    })()

    return (
      <Section key={it.id} width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('sections', it.id))}</SectionTitle>
        <SectionContent>{content}</SectionContent>
      </Section>
    )
  })

  return (
    <FullPage header={header} subheader={subheader} upButton={upButton}>
      <NotificationContainer mb={xlBumps}>
        <RenewalPromotionBanner />
      </NotificationContainer>

      <Section width={1} mb={xxlBumps}>
        <SectionTitle>{t(tKey('sections.updated-terms'))}</SectionTitle>
        <SectionContent>
          <ProposalAdjustmentPricing
            product={product}
            proposal={proposal}
            adjustedQuote={proposal.quote}
            originalQuote={proposal.sourcePolicy.quote}
            width={1}
            p={2}
            variant='raised'
            mb={smBumps}
          />
          <ProposalInformationSection proposal={proposal} />
        </SectionContent>
      </Section>

      {configuredSections}

      <ProposalCoverSection mb={xxlBumps} />

      <RenewalAddOnSection
        quote={quote}
        onChange={onAddOnChange}
        header={t(tKey('sections.your-add-ons'))}
        mb={xxlBumps}
      />

      <ExcessSection
        config={excess}
        currentQuote={quote}
        previousQuote={proposal.sourcePolicy.quote}
        onChange={onExcessChange}
        mb={xxlBumps}
      />

      <ProductDocumentsSection
        quote={quote}
        header={t(tKey('sections.your-documents'))}
      />
    </FullPage>
  )
}

interface RenewalQuotePageProps {
  home: Page
}

export const RenewalQuotePage: FunctionComponent<RenewalQuotePageProps> = ({ home }) => {
  const { proposal } = useProposal()

  return isRenewalProposal(proposal) ? (
    <RenewalQuotePageContent home={home} proposal={proposal} />
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
