import {
  Box,
  Form,
  mdBumps,
  PrimaryButton,
  RadioButton,
  RadioButtonGrid,
  smBumps,
  TextInput,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, KeyboardEvent, useCallback } from 'react'
import { UseAsyncReturn } from 'react-async-hook'
import { Controller, useForm } from 'react-hook-form'

import {
  TKey,
  tProduct,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { FeedbackFormData } from './FeedbackModal'

interface FeedbackFormProps {
  type: string
  onSubmit: UseAsyncReturn<void, [data: FeedbackFormData]>
}

const tKey = TKey('components.feedback-form')

const ratingValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const FeedbackForm: FunctionComponent<FeedbackFormProps> = ({
  type,
  onSubmit,
}) => {
  const { t } = useWriskTranslation()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FeedbackFormData>()

  const onCommentInputKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') event?.preventDefault?.()
  }, [])

  return (
    <Box width={1}>
      <Form data-testid='feedback' onSubmit={handleSubmit(onSubmit.execute)}>
        <Box width={1} mb={mdBumps}>
          <Typo mb={smBumps}>
            {t(tKey('rating', type, 'header'), {
              productName: t(tProduct('name-short'), t(tProduct('name'))),
            })}
          </Typo>
          <Controller
            name='rating'
            control={control}
            rules={{ required: true, validate: (value) => ratingValues.includes(value) }}
            render={({ field: { name: controllerName, value, onChange } }) => (
              <RadioButtonGrid width={1} columns={5}>
                {ratingValues.map((option) => (
                  <RadioButton
                    key={option}
                    name={controllerName}
                    value={option}
                    onChange={onChange}
                    checked={value === option}
                    data-testid={option}
                    layout='small'
                  >
                    {option}
                  </RadioButton>
                ))}
              </RadioButtonGrid>
            )}
          />
        </Box>

        <Box width={1} mb={mdBumps}>
          <Typo mb={smBumps}>{t(tKey('comment.header'))}</Typo>
          <Controller
            name='comment'
            control={control}
            render={({ field: { name: controllerName, value, onChange, onBlur } }) => (
              <TextInput
                name={controllerName}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                placeholder={t<string>(tKey('comment.placeholder'))}
                width='100%'
                onKeyPress={onCommentInputKeyPress}
              />
            )}
          />
        </Box>

        <PrimaryButton
          type='submit'
          layout='fixed'
          loading={onSubmit.loading}
          disabled={!isValid}
        >
          {t(tKey('actions.continue'))}
        </PrimaryButton>
      </Form>
    </Box>
  )
}
