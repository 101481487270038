import { css, variant } from '@wrisk/styled-system'
import styled from 'styled-components'

import { Typo } from './Typo'
import { TypoSizeProps, typoSizes } from './typoSizes'

export type PlateProperty = 'default'

export interface PlateProps extends TypoSizeProps {
  variant?: PlateProperty
}

export const Plate = styled(Typo)<PlateProps>(
  css({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    backgroundColor: 'surfaceDisabled',
    my: -1,
    py: 1,
    px: 2,
    borderRadius: 2,
  }),
  variant({
    scale: 'Plate',
    variants: {
      default: {},
    },
  }),
  typoSizes,
)

Plate.defaultProps = {
  variant: 'default',
}
