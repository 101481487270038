import { SystemStyleObject } from '@wrisk/styled-system'

import { ButtonProps } from '../Button'

export type ButtonLoadingProperty = 'default'

export interface ButtonLoadingProps {
  $loadingState?: ButtonLoadingProperty
}

export const buttonLoadingMapping = ({ loading }: ButtonProps): ButtonLoadingProps => ({
  $loadingState: loading ? 'default' : undefined,
})

export const buttonLoadingVariants: Record<ButtonLoadingProperty, SystemStyleObject> = {
  default: {
    color: 'transparent',
    'not(.loading)': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
}
