import { FlexProps } from '@wrisk/styled-system'
import {
  Box,
  Flex,
  Heading,
  lgBumps,
  mdBumps,
  SecondaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { useProduct } from '../../../../productContext'
import { PolicyPath } from '../../../PolicyPath'

const tKey = TKey('components.upload-documents-sidebar')

export const UploadDocumentsSidebar: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const {
    product: { uploads },
  } = useProduct()

  const onUpload = useCallback(
    (purpose: string) => {
      navigate(['..', PolicyPath.UPLOADS, purpose].join('/'))
    },
    [navigate],
  )

  return (
    <Flex
      width={1}
      flexDirection='column'
      alignItems='flex-start'
      variant='raised'
      mb={lgBumps}
      {...props}
    >
      <Box width={1} borderBottomWidth={1} p={mdBumps}>
        <Heading variant='h3' typoSize='md'>
          {t(tKey('header'))}
        </Heading>
      </Box>
      <Typo p={mdBumps}>{t(tKey('description'))}</Typo>
      <Flex
        justifyContent='center'
        flexDirection='column'
        width={1}
        px={mdBumps}
        pb={mdBumps}
      >
        {Object.keys(uploads ?? {}).map((purpose, index) => (
          <SecondaryButton
            key={purpose}
            mt={index ? 2 : 0}
            width={1}
            onClick={() => onUpload(purpose)}
          >
            {t(tKey('actions', purpose))}
          </SecondaryButton>
        ))}
      </Flex>
    </Flex>
  )
}
