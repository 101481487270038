import { SearchViewWithOverridableConfirmation } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback } from 'react'

import { SearchDataEntry } from '../../../../domain'
import { tDefaults, TKeyBuilder } from '../../../../infrastructure/internationalisation'
import LookupSearchView from './LookupSearchView'

interface LookupFinderProps {
  value?: string
  onChange: (value: SearchDataEntry | undefined) => void
  tableCode: string
  minimumLookupChars: number
  tName: string
  tKey: TKeyBuilder
  t: TFunction
}

export const LookupFinder: FunctionComponent<LookupFinderProps> = ({
  tName,
  value,
  tKey,
  t,
  onChange,
  tableCode,
  minimumLookupChars,
}) => {
  const renderSearch = useCallback(
    (onSelect) => (
      <LookupSearchView
        tName={tName}
        tKey={tKey}
        t={t}
        tableCode={tableCode}
        minimumLookupChars={minimumLookupChars}
        onSelect={onSelect}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableCode, minimumLookupChars],
  )

  const renderConfirmation = useCallback((t) => t.name ?? t, [])

  return (
    <SearchViewWithOverridableConfirmation
      value={value}
      onChange={onChange}
      renderConfirmation={renderConfirmation}
      renderSearch={renderSearch}
      changeAction={t(tDefaults('actions.change'))}
    />
  )
}
