import {
  BorderProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
} from '@wrisk/styled-system'
import React, { PropsWithChildren, ReactElement, useCallback } from 'react'

import { FlexAnchor, smBumps, TextTransformProps, TypoSizeProps, xsBumps } from '../atoms'
import { TextDecorationProps } from '../textDecoration'
import { TransitionProps } from '../transition'

export interface SelectResultProps<T>
  extends PropsWithChildren,
    BorderProps,
    TypoSizeProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    TypographyProps,
    TransitionProps,
    TextDecorationProps,
    TextTransformProps {
  onClickParams: T
  onClick: (params: T) => void
}

export const SelectResult = <T,>({
  children,
  onClick,
  onClickParams,
  ...props
}: SelectResultProps<T>): ReactElement => {
  const onInternalClick = useCallback(
    () => onClick?.(onClickParams),
    [onClick, onClickParams],
  )

  return (
    <FlexAnchor
      data-testid='select-result'
      as='li'
      role='option'
      variant='highlight'
      px={smBumps}
      py={xsBumps}
      width={1}
      {...props}
      onClick={onInternalClick}
    >
      {children}
    </FlexAnchor>
  )
}
