import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  css,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { transition, TransitionProps } from '../../transition'
import { whiteSpace, WhiteSpaceProps } from '../typography'
import { Button, ButtonProps } from './Button'
import {
  buttonDisabledVariants,
  ButtonLayoutProps,
  buttonLayoutVariant,
  buttonLoadingVariants,
  buttonStateMapping,
  ButtonStateProps,
  defaultButtonStyles,
} from './variants'

export type PrimaryButtonProperty = 'default' | 'inverted' | 'critical'

export interface PrimaryButtonProps
  extends ButtonProps,
    ButtonLayoutProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TransitionProps,
    WhiteSpaceProps,
    BorderProps {
  variant?: ResponsiveStyleValue<PrimaryButtonProperty>
}

export const PrimaryButton = styled(Button).attrs<ButtonStateProps>(
  buttonStateMapping,
)<PrimaryButtonProps>(
  defaultButtonStyles,
  css({
    '&:hover:not([disabled])': {
      boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.1)',
    },
    '&:active:not([disabled])': {
      boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.2)',
    },
  }),
  variant({
    scale: 'PrimaryButton.variants',
    variants: {
      default: {
        bg: 'primaryAccent',
        color: 'chalk',
      },
      inverted: {
        bg: 'chalk',
        color: 'primaryAccent',
      },
      critical: {
        bg: 'surfaceCritical',
        color: 'textOnCritical',
      },
    },
  }),
  variant({
    prop: '$loadingState',
    scale: 'PrimaryButton.loading',
    variants: buttonLoadingVariants,
  }),
  variant({
    prop: '$disabledState',
    scale: 'PrimaryButton.disabled',
    variants: buttonDisabledVariants,
  }),
  buttonLayoutVariant,
  compose(layout, space, color, transition, border, whiteSpace),
)

PrimaryButton.defaultProps = {
  variant: 'default',
  layout: 'default',
}
