import {
  compose,
  css,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveStyleValue,
  space,
  SpaceProps,
  TextAlignProps,
  typography,
  variant,
} from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Box, BoxProps, mdBumps, xlBumps, xxlBumps } from '../layout'
import { Heading, Typo } from '../typography'

export type HeaderProperty = 'default'

export interface HeaderProps
  extends FlexboxProps,
    TextAlignProps,
    SpaceProps,
    LayoutProps {
  variant?: ResponsiveStyleValue<HeaderProperty>
}

export const Header = styled.div<HeaderProps>(
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    my: xxlBumps,
  }),
  variant({
    scale: 'Header',
    variants: {
      default: {},
    },
  }),
  compose(flexbox, typography, space, layout),
)

Header.defaultProps = {
  variant: 'default',
}

export const HeaderTitle: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} {...props}>
    <Heading variant='h1' as='h1' typoSize='xxl'>
      {children}
    </Heading>
  </Box>
)

export const HeaderSubtitle: FunctionComponent<BoxProps> = ({ children, ...props }) => (
  <Box width={1} mt={mdBumps} {...props}>
    <Typo as='pre'>{children}</Typo>
  </Box>
)

export const HeaderLowerContent: FunctionComponent<BoxProps> = (props) => (
  <Box mt={mdBumps} width={1} {...props} />
)

export const HeaderUpperContent: FunctionComponent<BoxProps> = (props) => (
  <Box mb={xlBumps} width={1} {...props} />
)
