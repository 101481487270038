import React, { FunctionComponent, PropsWithChildren } from 'react'
import { config, useTransition } from 'react-spring'

import { AnimatedSnackBar } from './styles'

export interface SnackBarProps extends PropsWithChildren {
  open: boolean
}

export const SnackBar: FunctionComponent<SnackBarProps> = ({ children, open }) => {
  const frameTransitions = useTransition(open, {
    config: config.stiff,
    from: { opacity: 0, transform: 'translate(-50%, -20px)' },
    enter: { opacity: 1, transform: 'translate(-50%, 0px)' },
    leave: { opacity: 0, transform: 'translate(-50%, -20px)' },
  })

  const maybeSnackBar = frameTransitions(
    (props, item) =>
      item && <AnimatedSnackBar style={props}>{children}</AnimatedSnackBar>,
  )

  return <>{maybeSnackBar}</>
}
