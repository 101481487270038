import {
  color,
  ColorProps,
  compose,
  css,
  space,
  SpaceProps,
  variant,
} from '@wrisk/styled-system'
import styled from 'styled-components'

import { xxlBumps } from '../layout'

export type PageContainerProperty = 'default'

export interface PageContainerProps extends ColorProps, SpaceProps {
  variant?: PageContainerProperty
}

export const PageContainer = styled.section<PageContainerProps>(
  css({
    flex: '1 0 auto',
    pb: xxlBumps,
  }),
  variant({
    scale: 'PageContainer',
    variants: {
      default: {},
    },
  }),
  compose(color, space),
)

PageContainer.defaultProps = {
  variant: 'default',
}
