import { compose, css, space, SpaceProps, variant } from '@wrisk/styled-system'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export type LicencePlateProperty = 'default' | 'subtle'

const StyledLicencePlate = styled.div<LicencePlateProps>(
  css({
    textAlign: 'center',
    fontFamily: 'UKLicencePlate, sans-serif',
  }),
  ({ theme }) =>
    variant({
      scale: 'LicencePlate',
      variants: {
        default: {
          backgroundColor: '#ffd067',
          color: 'body',
        },
        subtle: {
          boxShadow: `inset 0 0 0 1px ${theme.colors.body}`,
          color: 'body',
        },
      },
    }),
  ({ theme }) => {
    return variant({
      prop: 'size',
      variants: {
        default: {
          py: 1,
          px: 4,
          minWidth: '3.5rem',
          borderRadius: 1,
          ...theme.typoSizes.base,
        },
        small: {
          py: 0,
          px: 2,
          borderRadius: 0,
          ...theme.typoSizes.sm,
        },
      },
    })
  },
  compose(space),
)

StyledLicencePlate.defaultProps = {
  variant: 'default',
  size: 'default',
}

export interface LicencePlateProps extends SpaceProps {
  licencePlate: string | undefined
  variant?: LicencePlateProperty
  size?: 'default' | 'small'
}

export const LicencePlate: FunctionComponent<LicencePlateProps> = (props) =>
  props.licencePlate !== undefined ? (
    <StyledLicencePlate {...props}>{props.licencePlate}</StyledLicencePlate>
  ) : null
